<template>
  <section>
    <h2>Licenses & Certifications</h2>
    <ul>
      <li>Certified Information Systems Security Professional (CISSP)</li>
      <li>Certified Secure Software Lifecycle Professional (CSSLP)</li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'CertificationsSection'
}
</script>

<style scoped>
/* Add styles specific to the Certifications component */
</style>
