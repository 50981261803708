<template>
  <section>
    <h2>About</h2>
    <p>A seasoned professional with a distinguished career in cybersecurity, Bülent Büyükkahraman graduated from Istanbul Technical University with a degree in Electronics and Communication Engineering in 1996. Over the years, he has held pivotal roles at globally renowned organizations, including IBM, Nokia, and Coca-Cola, as well as prominent Turkish companies such as Anadolu Group, Turkcell, and Netaş. His leadership journey spans from hands-on engineering to strategic directorship, consistently driving innovation and resilience in cybersecurity. As the founder of Axoss Cybersecurity Academy, he has actively contributed to cultivating talent and advancing the cybersecurity workforce, showcasing a commitment to both industry growth and community impact. Since 2022, he has been serving as the Cybersecurity Director at Anadolu Group, continuing to lead with expertise, integrity, and a vision for the future of digital security.</p>
  </section>
</template>

<script>
export default {
  name: 'AboutSection'
}
</script>

<style scoped>
/* Add styles specific to the About component */
</style>
