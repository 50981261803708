<template>
  <section>
    <h2>Education</h2>
    <div>
      <h3>Istanbul Technical University</h3>
      <p>Bachelor of Science (B.Sc.), Electronics and Communications Engineering</p>
      <p>1991 - 1996</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EducationSection'
}
</script>

<style scoped>
/* Add styles specific to the Education component */
</style>
